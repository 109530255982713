import { useEffect } from 'react';
import { useGetActiveMaintenancesQuery } from './maintenanceSlice';
import axiosInstance from '../../utils/axiosApi';
import { StatusCodes } from 'http-status-codes';
import { maintenance_reason } from '../../utils/constants';

const WithMaintenance = ({ children }) => {
  const { refetch } = useGetActiveMaintenancesQuery();

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response?.status ===
            StatusCodes.SERVICE_UNAVAILABLE &&
          error.response?.data?.metadata?.reason ===
            maintenance_reason
        ) {
          refetch();
          return error.response;
        }
        return Promise.reject(error);
      },
    );
    refetch();
  }, [refetch]);

  return children;
};

export default WithMaintenance;
