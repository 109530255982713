import React, { Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import RefundOrQuote from './components/account/refund_or_quote';
import { useGetActiveMaintenancesQuery } from './components/maintenance/maintenanceSlice';
import SignerPage from './components/subscription/formPro/entreprise/signer';
import LazyLoader from './utils/LazyLoader';
import {
  isAuthenticated,
  isPro,
  rightholder,
} from './utils/axiosApi';

const Home = React.lazy(() => import('./components/home'));
const Login = React.lazy(() => import('./components/login'));
const Subscription = React.lazy(
  () => import('./components/subscription'),
);
// const SubscriptionInd = React.lazy(() =>
//   import('./components/subscription/formInd'),
// );

const Dashboard = React.lazy(
  () => import('./components/account/dashboard/index.js'),
);
const FAQ = React.lazy(() => import('./components/account/faq'));
const Comptability = React.lazy(
  () => import('./components/account/admin/comptability'),
);
const Wallet = React.lazy(
  () => import('./components/account/admin/contracts'),
);
const Settings = React.lazy(
  () => import('./components/account/admin/settings'),
);
const DSN = React.lazy(
  () => import('./components/account/admin/settings/dsn'),
);
const ManageEmployee = React.lazy(
  () => import('./components/account/manage'),
);
const ManagePay = React.lazy(
  () => import('./components/account/pay'),
);
const Balance = React.lazy(
  () => import('./components/account/admin/comptability/balance'),
);
const Guarantees = React.lazy(
  () => import('./components/account/coverage'),
);
const MyRefundOrQuotes = React.lazy(
  () => import('./components/account/my_refunds_or_quotes'),
);
const Upgrade = React.lazy(
  () => import('./components/account/upgrade'),
);
const Advisor = React.lazy(
  () => import('./components/account/advisor'),
);
const Teams = React.lazy(() => import('./components/account/teams'));
const Documents = React.lazy(
  () => import('./components/account/documents'),
);
const Teleconsultation = React.lazy(
  () => import('./components/account/teleconsultation'),
);
const Cotisations = React.lazy(
  () => import('./components/account/cotisations'),
);

const AccountLayout = React.lazy(
  () => import('./components/TemplateLayoutAccount'),
);
const RightHolders = React.lazy(
  () => import('./components/account/rightholders'),
);
const PersonalInfo = React.lazy(
  () => import('./components/account/personal_info'),
);
const SubscriptionLayout = React.lazy(
  () => import('./components/SubscriptionLayout'),
);
const HomeLayout = React.lazy(
  () => import('./components/HomeLayout'),
);
const SmsAuthentication = React.lazy(
  () => import('./components/authentication/sms'),
);

const Politique = React.lazy(
  () => import('./components/PrivacyPolicy'),
);
const Mentions = React.lazy(() => import('./components/LegalNotice'));

export default function Router() {
  const role = isPro();
  const rightholderV = rightholder();
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';

  const { data } = useGetActiveMaintenancesQuery();

  const isMaintenanceActive = data?.active;

  const proRoutes = [
    {
      path: 'admin',
      element: <Outlet />,
      children: [
        { path: '', element: <Navigate to="dsn" replace /> },
        {
          path: 'dsn',
          element: <DSN />,
        },
        { path: 'settings', element: <Settings /> },
      ],
    },
    {
      path: 'manage',
      element: <ManageEmployee />,
    },

    { path: 'wallet', element: <Wallet /> },
    { path: 'pay', element: <ManagePay /> },
    {
      path: 'comptability',
      element: <Outlet />,
      children: [
        { path: '', element: <Navigate to="bills" replace /> },
        {
          path: 'bills',
          element: <Comptability />,
        },
        { path: 'balance', element: <Balance /> },
      ],
    },
    {
      path: 'coverage',
      element: <Guarantees />,
    },
    { path: 'teams', element: <Teams /> },
  ];

  const employeeRoutes = [
    {
      path: 'myrefunds',
      element: <MyRefundOrQuotes isQuotes={false} />,
    },
    {
      path: 'myquotes',
      element: <MyRefundOrQuotes isQuotes={true} />,
    },
    { path: 'faq', element: <FAQ /> },
    {
      path: 'refund',
      element: (
        <RefundOrQuote isQuote={false} rightholder={rightholderV} />
      ),
    },
    { path: 'prices', element: <Cotisations /> },
    {
      path: 'quote',
      element: (
        <RefundOrQuote isQuote={true} rightholder={rightholderV} />
      ),
    },
    { path: 'upgrade', element: <Upgrade /> },
    {
      path: 'rightholders',
      element: <RightHolders />,
    },
    { path: 'personal_info', element: <PersonalInfo /> },
    {
      path: 'teleconsultation',
      element: <Teleconsultation />,
    },
    { path: 'documents', element: <Documents /> },
    { path: 'advisor', element: <Advisor asPro={role} /> },
  ];

  return (
    <Suspense fallback={<LazyLoader />}>
      {useRoutes([
        {
          path: '/',
          element:
            isMobileApp && !isMaintenanceActive ? (
              <Navigate to="/login" />
            ) : (
              <HomeLayout asPro={role} />
            ),
          children: [
            { path: '/', element: <Home /> },
            {
              path: '/privacyPolicy',
              element: <Politique />,
            },
            { path: '/legalNotice', element: <Mentions /> },
          ],
        },
        !isMaintenanceActive && {
          path: '/login',
          element: !isAuthenticated() ? (
            <SubscriptionLayout />
          ) : (
            <Navigate to="/account/" />
          ),
          children: [{ path: '/login', element: <Login /> }],
        },
        !isMaintenanceActive && {
          path: '/account',
          element: isAuthenticated() ? (
            <AccountLayout asPro={role} rightholder={rightholderV} />
          ) : (
            <Navigate to="/" replace />
          ),
          children: [
            //Common
            {
              path: '',
              element: role ? (
                <Navigate to="manage" replace />
              ) : (
                <Dashboard />
              ),
            },
            { path: 'help', element: <Advisor asPro={role} /> },

            //Employee vs Pro routes
            ...(role ? proRoutes : employeeRoutes),
          ],
        },
        ...(!isMobileApp && !isMaintenanceActive
          ? [
              {
                path: '/subscription',
                element: <SubscriptionLayout />,
                children: [
                  {
                    path: '/subscription/:step',
                    element: <Subscription />,
                  },
                  {
                    path: '/subscription',
                    element: <Subscription />,
                  },
                ],
              },
              {
                path: '/signup',
                element: <SubscriptionLayout />,
                children: [
                  {
                    path: '/signup/:step',
                    element: <Subscription />,
                  },
                  {
                    path: '/signup',
                    element: <Subscription />,
                  },
                ],
              },
              { path: '/sms', element: <SmsAuthentication /> },
              {
                path: '/signer',
                element: <SubscriptionLayout />,
                children: [{ path: '', element: <SignerPage /> }],
              },
            ]
          : []),
        {
          path: '*',
          element: <Navigate to="/" replace />,
        },
      ])}
    </Suspense>
  );
}
