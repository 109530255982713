import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { maintenance_reason } from './constants';

const baseURL = `${process.env.REACT_APP_BACKEND_URL}`;

const accessToken = localStorage.getItem('access_token') || null;

const axiosInstance = axios.create({
  baseURL,
  timeout: 0,
  headers: {
    Authorization: accessToken ? `JWT ${accessToken}` : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

Date.prototype.toJSON = function () {
  const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
  this.setHours(hoursDiff);
  return this.toISOString();
};

axiosInstance.interceptors.response.use(
  (response) => {
    if (String(response.status)[0] === '2')
      // type 2XX
      return Promise.resolve(response);
    else return Promise.reject(response);
  },
  (error) => {
    const { response, config: originalRequest } = error;
    console.log('interceptor axios');
    // Prevent infinite loops
    if (
      response.status === 401 &&
      originalRequest.url === `${baseURL}token/refresh/`
    ) {
      window.location.href = '/login';
      return Promise.reject(response?.data);
    }
    if (
      response.data.code === 'token_not_valid' &&
      response.status === 401
      // && response.statusText === "Unauthorized"
    ) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        window.location.href = '/login';
        return;
      }
      const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
      // exp date in token is expressed in seconds, while now() returns milliseconds:
      const now = Math.ceil(Date.now() / 1000);

      if (tokenParts.exp > now) {
        return axiosInstance
          .post('/today/token/refresh/', { refresh: refreshToken })
          .then((response) => {
            localStorage.setItem(
              'access_token',
              response.data.access,
            );
            localStorage.setItem(
              'refresh_token',
              response.data.refresh,
            );
            axiosInstance.defaults.headers['Authorization'] =
              `JWT ${response.data.access}`;
            originalRequest.headers['Authorization'] =
              `JWT ${response.data.access}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            console.log('ERROR: ', err);
          });
      } else {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        axiosInstance.defaults.headers['Authorization'] = null;
        originalRequest.headers['Authorization'] = null;
        return axiosInstance(originalRequest);
      }
    }
    const apiErrorInfo = {
      'API Request': {
        URL: `${originalRequest?.baseURL}${originalRequest?.url}`,
        Method: originalRequest?.method?.toUpperCase(),
        Data: JSON.stringify(originalRequest?.data)?.slice(0, 500),
      },
      'API Response': {
        Status: error.response?.status,
        'Status text': error.response?.statusText?.slice(0, 500),
        Data: JSON.stringify(error.response?.data)?.slice(0, 500),
      },
    };
    const errorEvent = {
      message: `${error?.name} - ${error.message} on ${originalRequest.url}`,
      level: 'error',
      extra: apiErrorInfo,
    };
    Sentry.captureEvent(errorEvent);
    try {
      datadogRum.addError('API Error', {
        error: apiErrorInfo,
        message: error?.message,
        stack: error?.stack,
      });
    } catch (e) {
      console.log(
        `Error while adding custom error to datadog : ${error.message}`,
      );
    }
    if (
      error.response?.status === StatusCodes.SERVICE_UNAVAILABLE &&
      error.response?.data?.metadata?.reason === maintenance_reason
    ) {
      return Promise.reject(error);
    }
    return Promise.reject(response?.data);
  },
);

export const isAuthenticated = () => {
  const token = localStorage.getItem('access_token');
  return !!token;
};

export const isPro = () => {
  const role = localStorage.getItem('role');
  return isAuthenticated() && role === 'p';
};

export const rightholder = () => {
  let rightholder = localStorage.getItem('rightholder');
  if (rightholder === 'null') {
    rightholder = null;
  } else {
    rightholder = JSON.parse(rightholder);
  }
  return isAuthenticated() && rightholder;
};

export const logoutUser = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('role');
  localStorage.removeItem('rightholder_id');
  datadogRum.clearUser();
  window.location.href = '/';
};

export default axiosInstance;
