import React from 'react';
import Carousel from 'react-elastic-carousel';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'inherit',
    flexDirection: 'row !important',
    paddingRight: 10,
    paddingLeft: 10,
  },
}));

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 320, itemsToShow: 2, itemsToScroll: 2 },
  { width: 470, itemsToShow: 3, itemsToScroll: 3 },
  { width: 700, itemsToShow: 4, itemsToScroll: 4 },
];

export default function CustomCarousel({ children, ...props }) {
  const classes = useStyles();

  return (
    <Carousel
      className={classes.root}
      breakPoints={breakPoints}
      pagination={false}
    >
      {children}
    </Carousel>
  );
}
