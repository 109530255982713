import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from '../utils/axiosRequests';

const initialState = {
  status: 'idle',
  id: null,
  ubcId: null,
  firstName: '',
  lastName: '',
  address: {},
  email: '',
  phone: '',
  birthday: '',
  rightholders: [],
  createdAt: '',
  contract: '',
  contract_insured: null,
  iban: '',
  bic: '',
  teletransmission: null,
  secuNumber: '',
  data: {},
  startDate: '',
  contract_option_resiliated_at: '',
  option_available: false,
  organism: '',
  mutualisation: '',
  complexCollege: true,
  last_contract_situation: '',
  files: {},
  rightholders_allowed: false,
};

export const getAccount = createAsyncThunk(
  'getAccount',
  async (data, { getState }) => {
    const response = await api.getAccount();
    return response.data;
  },
);

export const updateAccount = createAsyncThunk(
  'updateAccount',
  async (data, { getState }) => {
    const response = await api.updateAccount(data);
    return response.data;
  },
);

export const getFiles = createAsyncThunk('getFiles', async () => {
  const response = await api.getFiles();
  return response.data;
});

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    reset: (state) => {
      state.status = initialState.status;
      state.id = initialState.id;
      state.ubcId = initialState.ubcId;
      state.firstName = initialState.firstName;
      state.lastName = initialState.lastName;
      state.address = initialState.address;
      state.email = initialState.email;
      state.phone = initialState.phone;
      state.birthday = initialState.birthday;
      state.rightholders = initialState.rightholders;
      state.contract = initialState.contract_status;
      state.contract_insured = initialState.etiq_contract_insured;
      state.iban = initialState.iban;
      state.bic = initialState.bic;
      state.teletransmission = initialState.teletransmission;
      state.secuNumber = initialState.secu_number;
      state.createdAt = initialState.created_at;
      state.startDate = initialState.start_date;
      state.option_available = initialState.option_available;
      state.organism = initialState.organism;
      state.mutualisation = initialState.mutualisation;
      state.complexCollege = initialState.complexCollege;
      state.data = initialState.data;
      state.last_contract_situation =
        initialState.last_contract_situation;
      state.rightholders_allowed = initialState.rightholders_allowed;
      state.contract_option_resiliated_at =
        initialState.contract_option_resiliated_at;
      state.files = initialState.files;
    },
  },
  extraReducers: {
    [getAccount.pending]: (state) => {
      state.status = 'loading';
    },
    [getAccount.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
      state.id = payload.id;
      state.firstName = payload.first_name;
      state.lastName = payload.last_name;
      state.address = payload.address;
      state.email = payload.email;
      state.phone = payload.phone;
      state.birthday = payload.birthday;
      state.rightholders = payload.rightholders;
      state.contract = payload.contract_employee_category;
      state.contract_insured = payload.etiq_contract_insured;
      state.iban = payload.iban;
      state.bic = payload.bic;
      state.teletransmission = payload.teletransmission;
      state.secuNumber = payload.secu_number;
      state.createdAt = payload.created_at;
      state.startDate = payload.start_date;
      state.option_available = payload.option_available;
      state.organism = payload.organism;
      state.mutualisation = payload.mutualisation;
      state.complexCollege = payload.complexCollege;
      state.last_contract_situation = payload.last_contract_situation;
      state.rightholders_allowed = payload.rightholders_allowed;
      state.ubcId = payload.etiq_contract_insured
        ? payload.etiq_contract_insured.id
        : null;
      state.contract_option_resiliated_at =
        payload.contract_option_resiliated_at;
      state.data = payload;
      state.files = initialState.files;
    },
    [getAccount.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
    [updateAccount.pending]: (state) => {
      state.status = 'loading';
    },
    [updateAccount.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
      state.id = payload.id;
      state.firstName = payload.first_name;
      state.lastName = payload.last_name;
      state.address = payload.address;
      state.email = payload.email;
      state.phone = payload.phone;
      state.birthday = payload.birthday;
      state.rightholders = payload.rightholders;
      state.contract = payload.contract_employee_category;
      state.iban = payload.iban;
      state.bic = payload.bic;
      state.teletransmission = payload.teletransmission;
      state.secuNumber = payload.secu_number;
      state.startDate = payload.start_date;
      state.option_available = payload.option_available;
      state.organism = payload.organism;
      state.mutualisation = payload.mutualisation;
      state.complexCollege = payload.complexCollege;
      state.last_contract_situation = payload.last_contract_situation;
      state.rightholders_allowed = payload.rightholders_allowed;
      state.data = payload;
    },
    [updateAccount.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
    [getFiles.pending]: (state) => {
      state.status = 'loading';
    },
    [getFiles.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
      state.files = payload;
    },
    [getFiles.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
  },
});

// Action creators are generated for each case reducer function
export const { reset } = accountSlice.actions;

export default accountSlice.reducer;
