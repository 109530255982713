import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, InputAdornment } from '@mui/material';

export default function Step2(props) {
  const { t } = useTranslation('account', {
    keyPrefix: 'getrefund.step2',
  });
  const { handleNext, handleBack } = props;
  const [amountValue, setAmountValue] = useState(null);

  return (
    <section
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: { xs: '60%', md: '90%' },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant={'h2'}
          display={'flex'}
          justifyContent={'center'}
        >
          {props.isQuote ? t('title_quote') : t('title_refund')}
        </Typography>
        <section
          style={{
            height: '90%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            type="number"
            label={t('label_amount')}
            error={!!amountValue && amountValue < 1}
            onChange={(e) => {
              setAmountValue(e.target?.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€ TTC</InputAdornment>
              ),
            }}
          />
        </section>
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Button
          variant="contained"
          style={{ fontSize: '18px' }}
          color="button"
          onClick={handleBack}
        >
          {t('prev')}
        </Button>
        <Button
          variant="contained"
          style={{ fontSize: '18px', marginLeft: '50px' }}
          color="button"
          disabled={!amountValue || amountValue < 1}
          onClick={() => {
            handleNext({ cost: amountValue });
          }}
        >
          {t('next')}
        </Button>
      </div>
    </section>
  );
}
