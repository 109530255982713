import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import React from 'react';

const DEFAULT_MAX_DATE = new Date().setFullYear(
  new Date().getFullYear() + 2,
);
const DEFAULT_MIN_DATE = new Date('1940-01-01');

const useStyles = makeStyles((theme) => ({
  text_field_label: {
    color: theme.palette.primary.main,
  },
  text_field: {
    color: theme.palette.primary.main,
    '&:before': {
      color: theme.palette.primary.main,
    },
    '&:after': {
      color: theme.palette.primary.main,
    },
  },
  text_field_label_error: {
    color: theme.palette.error.main + ' !important',
  },
  text_field_error: {
    color: theme.palette.error.main + ' !important',
    '&:before': {
      borderColor: theme.palette.error.main + ' !important',
    },
    '&:after': {
      borderColor: theme.palette.error.main + ' !important',
    },
  },
  input: {
    width: '100%',
  },
}));

export default function CustomDatePicker({
  maxDate = DEFAULT_MAX_DATE,
  minDate = DEFAULT_MIN_DATE,
  defaultValue = undefined,
  dateValue = undefined,
  onChange = () => {},
  onAccept = () => {},
  field = {},
  views = ['year', 'month', 'day'],
  inputProps = {},
  label = null,
  setError = () => {},
  variant = 'outlined',
  error = false,
  disabled = false,
  className = '',
  textFieldProps = {},
  fullWidth = true,
}) {
  const classes = useStyles();

  return (
    <DatePicker
      openTo="year"
      views={views}
      value={dateValue || field?.value || null}
      minDate={minDate}
      onError={setError}
      disabled={disabled}
      label={label}
      maxDate={maxDate}
      onChange={(newValue) => {
        if (field?.onChange) field.onChange(newValue);
        else onChange(newValue);
      }}
      onAccept={onAccept}
      slotProps={{
        textField: {
          ...textFieldProps,
          className: fullWidth
            ? clsx(classes.input, className)
            : className,
          InputLabelProps: {
            className: error
              ? classes.text_field_label_error
              : variant === 'standard'
                ? classes.text_field_label
                : null,
          },
          InputProps: {
            className: error ? classes.text_field_error : null,
          },
          variant,
          helperText: null,
        },
      }}
      InputProps={{ sx: inputProps }}
    />
  );
}
