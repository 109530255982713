import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Dropzone from '../../molecules/Dropzone';
import Stack from '@mui/material/Stack';

export default function Step5(props) {
  const { t } = useTranslation('account', {
    keyPrefix: 'getrefund.step5',
  });
  const { handleNext, handleBack } = props;
  const [files, setFiles] = useState([]);

  return (
    <section
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: { xs: '60%', md: '90%' },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant={'h2'}
          display={'flex'}
          justifyContent={'center'}
        >
          {props.isQuote ? t('title_quote') : t('title_refund')}
        </Typography>
        {props.isQuote ? null : (
          <Typography
            variant={'h2'}
            display={'flex'}
            justifyContent={'center'}
          >
            {t('title_quote_2')}
          </Typography>
        )}
        <Stack
          sx={{
            marginTop: { xs: '20px', sm: '30px' },
            height: '40%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Dropzone files={[files, setFiles]} />
        </Stack>
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Button
          variant="contained"
          style={{ fontSize: '18px' }}
          color="button"
          onClick={handleBack}
        >
          {t('prev')}
        </Button>
        <Button
          variant="contained"
          style={{ fontSize: '18px', marginLeft: '50px' }}
          color="button"
          disabled={files?.length < 1}
          onClick={() => {
            handleNext({ files });
          }}
        >
          {t('next')}
        </Button>
      </div>
    </section>
  );
}
