import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Stepper, Step, StepLabel } from '@mui/material';
import Step0 from './Step0';
import Step1 from './Step1';
import { makeStyles } from '@mui/styles';
import Step2 from './Step2';
import Step3 from './Step3';
import Step5 from './Step5';
import Fade from '@mui/material/Fade';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  createRefund,
  getRightholders,
} from '../../../utils/axiosRequests';
import _ from 'lodash';
import Loading from '../../molecules/Loading';
import { useSelector } from 'react-redux';
import Hidden from '@mui/material/Hidden';

const useStyles = makeStyles((theme) => ({
  section: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '30px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '15px',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  final_icon: {
    fontSize: '10rem',
  },
}));

const getInitialForm = (isQuote, declarant) => ({
  typeClaim: isQuote ? 'quote' : 'refund',
  typeCare: null,
  declarant,
  cost: null,
  date: null,
  context: null,
  rightholderId: null,
});

export default function RefundOrQuote({ isQuote, rightholder }) {
  const classes = useStyles();
  const { t } = useTranslation('account', { keyPrefix: 'getrefund' });
  const [isLoading, setIsLoading] = React.useState(true);
  const [rightholders, setRightholders] = React.useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState(getInitialForm(isQuote));
  const [isFormSent, setIsFormSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const { id: userId } = useSelector((state) => state.account);

  useEffect(() => {
    setErrorMsg(null);
    setIsFormSent(false);
    setForm(getInitialForm(isQuote, userId));
    setActiveStep(0);
    setIsLoading(false);
  }, [isQuote, rightholders, userId]);

  useEffect(() => {
    setIsLoading(true);
    getRightholders().then(({ data }) => {
      setRightholders(data);
      setIsLoading(false);
    });
  }, []);

  const sendRefund = (files) => {
    const formData = new FormData();
    if (files?.length > 0) {
      Array.from(files).forEach((file) =>
        formData.append('files', file),
      );
    }
    Object.keys(_.omitBy(_.omit(form, 'files'), _.isNil)).forEach(
      (key) => {
        formData.append(key, form[key]?.toString()?.trim());
      },
    );
    createRefund(formData)
      .catch((error) => {
        setErrorMsg(error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsFormSent(true);
      });
  };

  const handleNext = (data) => {
    if (activeStep + 1 < steps.length) {
      setForm({ ...form, ...data });
      setActiveStep(activeStep + 1);
    } else if (activeStep + 1 === steps.length) {
      setIsLoading(true);
      sendRefund(data?.files);
    }
  };

  const handleBack = () => {
    if (activeStep - 1 >= 0) setActiveStep(activeStep - 1);
  };

  const steps = [
    ...(rightholders?.length
      ? [
          {
            label: t('stepper_who'),
            content: (
              <Step0
                rightholders={rightholders}
                isQuote={isQuote}
                handleNext={handleNext}
                rightholder={rightholder}
              />
            ),
          },
        ]
      : []),
    {
      label: t('stepper_why'),
      content: (
        <Step1
          hasStep0={rightholders?.length}
          handleNext={handleNext}
          isQuote={isQuote}
          handleBack={handleBack}
        />
      ),
    },
    {
      label: t('stepper_amount'),
      content: (
        <Step2
          handleNext={handleNext}
          isQuote={isQuote}
          handleBack={handleBack}
        />
      ),
    },
    ...(isQuote
      ? []
      : [
          {
            label: 'Date',
            content: (
              <Step3
                handleNext={handleNext}
                handleBack={handleBack}
              />
            ),
          },
        ]),
    // ATTENTION: if 'Contexte' is re-enabled, it is not responsive !!!
    /*{
            label: 'Contexte',
            content: <Step4 handleNext={handleNext} handleBack={handleBack}/>
        },*/
    {
      label: t('stepper_files'),
      content: (
        <Step5
          handleNext={handleNext}
          isQuote={isQuote}
          handleBack={handleBack}
        />
      ),
    },
  ];

  return (
    <Fade in={true} timeout={350} appear unmountOnExit>
      <section className={classes.section}>
        {isLoading ? (
          <Loading />
        ) : !isFormSent ? (
          <>
            <Stepper activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel>
                    <Hidden smDown>{step.label}</Hidden>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={classes.content}>
              {steps[activeStep].content}
            </div>
          </>
        ) : (
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {errorMsg ? (
              <>
                <ErrorOutlineIcon
                  color="error"
                  className={classes.final_icon}
                />
                <Typography
                  sx={{ mt: 2, mb: 1, fontSize: '18px' }}
                  color="error"
                >
                  {errorMsg}
                </Typography>
              </>
            ) : (
              <>
                <CheckCircleOutlineIcon
                  color="success"
                  className={classes.final_icon}
                />
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '18px',
                    mt: 3,
                    ml: 1,
                    mr: 1,
                  }}
                >
                  <Trans i18nKey="confirm_message" t={t} />
                </Typography>
              </>
            )}
          </section>
        )}
      </section>
    </Fade>
  );
}
